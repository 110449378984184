import API from "@/api";

export default {
  getData: params => {
    return API.requestGet({
      url: "index/getDatas",
      params,
      auth: "true"
    });
  },
  /**
   * 统计近半个月查询用户
   * @returns 
   */
  statics: () => {
    return API.requestGet({
      url: "friend/statics",
      auth: "true"
    });
  }
};
